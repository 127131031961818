export const environment = {
  domainName: 'Coding with Kids',
  imagesServer: 'd3t4xfu733v2tb.cloudfront.net',
  localStoragePrefix: 'CWK.',
  name: null,
  production: true,
  project: 'cwk',
  shortName: 'prod',
  stripeKey: 'pk_live_JIyVbmvfX83ZLroT4mIsZMoy',
  urlAppAdminPrefix: 'admin',
  urlAppAmazonScholarshipPrefix: 'amazon',
  urlAppCurriculumPrefix: 'curriculum',
  urlAppHomePrefix: '',
  urlAppLandingPagesPrefix: 'welcome',
  urlAppTrainingPrefix: 'training',
  urlConfirmationPath: 'confirmations',
  urlRoot: 'https://www.codingwithkids.com',
  urlSignOut: 'https://www.codingwithkids.com',
  urlStudentProfilePath: 'profile',
  urlWebServices: 'https://www.codingwithkids.com/be/index.php'
};
